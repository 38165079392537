import React, {useState, useEffect} from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import ImageWithTitleComponent from '../General/ImageWithTitleComponent'
import LinkButton from '../General/Buttons/Link'
import SectionWrapper from '../General/SectionWrapper' 
import { useStaticQuery, graphql } from 'gatsby'
import Carousel from '../General/Carousel'
import NextIcon from '@material-ui/icons/NavigateNext'
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { brandingConstant } from '../../utitlities/Configurations'
import { fetchData } from '../../utitlities/helperFunctions'

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: "1px 10px",
        [theme.breakpoints.up('md')]: {
            padding: "1px 20px"
        }
    },
    popupClass: {
        "& > div": {
            zIndex: "999999 !important"
        }
    }
}))


export default function Portfolio({
    title = 'Checkout Some of Our Super-Work', secondaryTitles = ['Super-Work'], clipList = false,
     listSpacing = 3, listXs = 12,
    listSm = 6, listMd = 4, sectionId = 'portfolio', sectionStyles = {}, sortKey = 'order',
    showTitle = true, serviceId = '', expandBrandingCards = true, portfolioId = ''
}) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [startIndex, setStartIndex] = React.useState(0)
    const imageQuery = useStaticQuery(graphql`
    query PorfolioImageQuery {        
        portfoliolist: allApiPortfolio {
            edges {
              node {
                brand_content {
                  id
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
                commercial_photography {
                  id
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
                commercial_videography {
                  id
                  link
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
                influencer_marketing {
                  id
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
                social_media_marketing {
                  id
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
                web_development {
                  id
                  order
                  photo_url
                  photo_url_thumbnail
                  service_id
                  type
                }
              }
            }
          }           
      }
    `)
    const [PortfolioData,SetData] = useState()     
    useEffect(() => { 
      fetchData(`portfolio`)
      .then(x => SetData(x) )         
  }, []); 

    const PortfolioList  =  PortfolioData ? PortfolioData : imageQuery.portfoliolist.edges[0].node     
    let imageListData = []     
        
    if (!expandBrandingCards && (serviceId === brandingConstant)) {        
      
    } else {         
        for (let pitems in PortfolioList) {
            let relativeDirectory = pitems.replace("_", "-")              
           if ((relativeDirectory === serviceId || serviceId === '')) {
            var arrayLength = PortfolioList[pitems].length
            
            for (var j = 0; j < arrayLength; j++) {
            if (PortfolioList[pitems][j].photo_url_thumbnail) {
                var imgThumbUrl = PortfolioList[pitems][j].photo_url_thumbnail
               let spt = imgThumbUrl.split('/')    
               let imgName = spt[spt.length - 1].split('.').join('')
               imageListData.push({"name":imgName, "publicURL":imgThumbUrl, type: PortfolioList[pitems][j].type === 'video' ? PortfolioList[pitems][j].type : 'photo', "url":PortfolioList[pitems][j].photo_url, link: PortfolioList[pitems][j].link})                
               }
            }
           }
            
              
           }       
       
    }
     
    const finalWidgetList = imageListData.map(item  => 
        ({
            type: item.type,
            altTag: item.name,
            title: item.name,
            url: (item.type === 'photo') ? item.url : item.link
        })
    )     
    const finalImageList = imageListData       
     
    return <SectionWrapper sectionId={sectionId} styles={(showTitle || clipList) ? sectionStyles : { ...sectionStyles, padding: '0px' }}>
        <Grid container spacing={(showTitle || clipList) ? 4 : 0}>
            {
                showTitle &&
                <Grid item xs={12}>
                    <div style={{
                        paddingBottom: '3rem'
                    }}>
                        <TitleComponent
                            title={title} secondaryTitles={secondaryTitles}
                            smallTitle='Portfolio'
                            subHeading="Our clients have trusted us for our out of box ideas and innovative concepts. Check out some of them."
                        />
                    </div>
                </Grid>
            }
            <Grid item xs={12}>
                {
                    clipList ?
                        <Carousel dataList={finalImageList}>
                            {
                                finalImageList.map((item, index) => {
                                    return <div className={classes.cardContainer}>
                                        <ImageWithTitleComponent
                                            imageApiUrl={item.publicURL}
                                            imageAlt={item.name}                                             
                                            onClick={!!item.route ? () => null :
                                                () => {
                                                    setStartIndex(index)
                                                    setOpen(true)
                                                }}                                            
                                        />
                                    </div>
                                })
                            }

                        </Carousel> :
                        <Grid container spacing={listSpacing}>
                            {
                                finalImageList.map((item, index) => {
                                    return <Grid item xs={listXs} sm={listSm} md={listMd}>                                         
                                        <ImageWithTitleComponent                                             
                                            imageApiUrl={item.publicURL}
                                            imageAlt={item.name}                                             
                                            onClick={!!item.route ? () => null :
                                                () => {
                                                    setStartIndex(index)
                                                    setOpen(true)
                                                }}                                            
                                        />
                                    </Grid>
                                })
                            }
                        </Grid>
                }
            </Grid>
            {
                clipList &&
                <Grid item xs={12} >
                    <Grid container justifyContent='center'>
                        <LinkButton
                            label='View All'
                            buttonProps={{
                                id: 'view-all',
                                endIcon: <NextIcon fontSize='small' />,
                                color: 'primary',
                                href: '/portfolio'
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </Grid>
        {
            open &&
            <div className={classes.popupClass}>
                <ReactImageVideoLightbox
                    startIndex={startIndex}
                    showResourceCount={false}
                    onCloseCallback={() => {
                        setOpen(false)
                        setStartIndex(0)
                    }}
                    data={finalWidgetList}
                />
            </div>
        }
    </SectionWrapper >
}
