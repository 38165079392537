import React from 'react'
import { Link } from 'gatsby' 
import { makeStyles } from '@material-ui/core' 
import { generalTextColor } from '../../utitlities/Configurations' 
import { GatsbyImage } from 'gatsby-plugin-image'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        paddingBottom: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '6px',
        cursor: 'pointer',        
    },
    imageContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 1,
    },
    textContainer: {
        margin: "-80px auto auto",
        borderRadius: '6px',
        backgroundColor: 'white',
        position: 'relative',
        padding: "1rem",
        zIndex: 2,
        width: "90%",
        [theme.breakpoints.up('sm')]: {
            padding: "1.5rem 1rem"
        }
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -1,
        borderRadius: '6px',
        transition: 'transform 0.5s ease !important'
    },
    secondaryText: {
        color: generalTextColor,
        fontSize: '0.75rem',
        fontWeight: 600
    },
    linkClass: {
        display: 'inline-block',
        height: '100%',
    }
}))

export default function ImageWithTitleComponent({
    image = '', imageApiUrl = '', imageAlt = '', title = '', isLink = true, route = '', divId = 'card', titleComponent = 'h3',
    titleVariant = 'h6',
    tags = [], date = '', dateFormat = 'MMMM DD YYYY', onClick = () => null
}) {
    const classes = useStyles()
    return <div id={divId} className={classes.container} onClick={onClick}>
        <div className={classes.imageContainer}>
            {
                (!!image || !!imageApiUrl) &&
                <>
                    {

                  imageApiUrl ?    

                  <img src={imageApiUrl} alt={imageAlt} className={classes.image} />
                  :
                        isLink ?
                            <Link to={route} className={classes.linkClass}>
                                <GatsbyImage image={image} alt={imageAlt} className={classes.image} /></Link>
                            :
                            <GatsbyImage image={image} alt={imageAlt} className={classes.image} />
                    }
                </>
            }

        </div>
       
    </div>
}